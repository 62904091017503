import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  SHOW_MESSAGE,
  TOGGLE_APP_DRAWER,
  UPDATING_CONTENT,
} from '../../shared/constants/ActionTypes';
//Cloud watch logs require functionality
var winston = require('winston'),
  WinstonCloudWatch = require('winston-cloudwatch');

const REGION = 'ap-south-1';
const GROUPNAME = 'gudmedweblog';

const getWinstonConfig = (streamName) => {
  
  const config = {
    logGroupName: GROUPNAME,
    logStreamName: `${streamName}_Web`,
    awsRegion: REGION,
  };

 
  // if (window.location.hostname === 'localhost') {
  //   return {
  //     ...config,
  //     awsAccessKeyId: ACCESSKEY,
  //     secretAccessKey: SECRETKEY
  //   };
  // }

 
  return config;
};


export const fetchStart = () => {
  return (dispatch) => dispatch({type: FETCH_START});
};

export const fetchSuccess = () => {
  return (dispatch) => dispatch({type: FETCH_SUCCESS});
};
export const updatingContent = () => {
  return (dispatch) => dispatch({type: UPDATING_CONTENT});
};

export const fetchError = (error) => {
  return (dispatch) => dispatch({type: FETCH_ERROR, payload: error});
};

export const showMessage = (message) => {
  return (dispatch) => dispatch({type: SHOW_MESSAGE, payload: message});
};
export const onToggleAppDrawer = () => {
  return (dispatch) => dispatch({type: TOGGLE_APP_DRAWER});
};

export const hideMessage = () => {
  return (dispatch) => dispatch({type: HIDE_MESSAGE});
};

export const onWriteCloudWatchLog = (
  streamName,
  filename,
  functionname,
  usercode,
  error,
) => {
  return async (dispatch) => {
    try {
      
      const cloudWatchConfig = getWinstonConfig(streamName);
      
      // Create transport with environment-specific config
      const transport = new WinstonCloudWatch(cloudWatchConfig);

      // Create logger instance
      const logger = winston.createLogger({
        transports: [transport]
      });

      // Format error message
      const errorMessage = {
        timestamp: new Date().toISOString(),
        source: 'WEB',
        fileName: filename,
        function: functionname,
        userCode: usercode,
        error: error instanceof Error ? error.message : error,
        stack: error instanceof Error ? error.stack : undefined
      };

      // Log error
      logger.error(JSON.stringify(errorMessage));

      // Optional: Handle successful logging
      transport.on('finish', () => {
        console.log('Log sent to CloudWatch successfully');
      });

    } catch (loggingError) {
      console.error('Error writing to CloudWatch:', loggingError);
      dispatch(showMessage({
        message: 'Error logging to CloudWatch',
        type: 'error'
      }));
    }
  };
};
