/************
 * Developed by : Shiva Software Solutions
 * Date : 22-06-2021
 * Descriptions : User Mangament api calling
 ************/
import {
  FETCH_ERROR,
  SHOW_MESSAGE,
  FETCH_START,
  FETCH_SUCCESS,
  GET_USERMANAGE_DATA,
  DELETE_USER_DATA,
  GET_GUDMEDSUCCESS_DATA,
  GET_GUDMED_USER,
  RESET_USER_PASWD 
} from '../../shared/constants/ActionTypes';
import IntlMessages from '../../@crema/utility/IntlMessages';
import React from 'react';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {fetchError} from './Common';
//Get User list data
export const onGetUserManagementData = ({userId, skip, limit}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {userId, skip, limit};
    //Call User list Token  - Get Token
    try {
      jwtAxios
        .post('v1/userMgmt/getusersjwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call User list data - Get user list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {userId, jwtToken};
              jwtAxios
                .post('v1/userMgmt/getusers', bodytoken)
                .then((data) => {
                  // console.log(data.data);
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    var arraydata = data.data.body.data.map((e, i) => {
                      return {
                        fName: e.fName,
                        lName: e.lName,
                        userId: e.userId,
                        email: e.email,
                        roleName: e.roleName,
                        mobileNbr: e.mobileNbr,
                        status: e.status,
                        statusName: e.statusName,
                      };
                    });
                    dispatch({
                      type: GET_USERMANAGE_DATA,
                      payload: arraydata,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                });
            } catch (err) {
              // console.log('error!!!!', err.response.message);
              dispatch(fetchError(err.response.message));
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
        });
    } catch (err) {
      // console.log('error!!!!', err.response.message);
      dispatch(fetchError(err.response.message));
    }
  };
};

// Delete user data
export const onDeleteUser = ({email, userId, loginuserid, loginUserCode}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {email, userId, loginUserCode};

    //Call Delete user - get Token
    try {
      jwtAxios
        .post('v1/userMgmt/deleteUserjwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call Delete user data - Delete userdata using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {userId, loginUserCode, jwtToken};
              jwtAxios
                .post('v1/userMgmt/deleteUser', bodytoken)
                .then((data) => {
                  // console.log(data.data);
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getuserdata = data.data.message;
                    dispatch({
                      type: DELETE_USER_DATA,
                      payload: getuserdata,
                    });
                    dispatch(
                      onGetUserManagementData({
                        userId: loginuserid,
                        skip: '0',
                        limit: '10',
                      }),
                    );
                    dispatch({
                      type: SHOW_MESSAGE,
                      payload: getuserdata,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                });
            } catch (err) {
              // console.log('error!!!!', err.response.message);
              dispatch(fetchError(err.response.message));
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
        });
    } catch (err) {
      // console.log('error!!!!', err.response.message);
      dispatch(fetchError(err.response.message));
    }
  };
};

// Reset User Password
export const onresetPassword = ({
  oldpaswd,
  newpaswd,
  mobileNbr,
  patientCode,viasetpaswd,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {oldpaswd, newpaswd, mobileNbr, patientCode,viasetpaswd};
    //Call Reset User Password   - get Token
    try {
      jwtAxios
        .post('v1/patMgmt/changepaswdjwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call Reset User Password  - Reset User Password   using token
            try {
              if (data.data.hasOwnProperty.call(data.data, 'message')) {
                // dispatch({type: FETCH_ERROR, payload: data.data.message});
                dispatch({
                  type: RESET_USER_PASWD,
                  payload: 'incorrect',
                });
                return;
              } else {
                const jwtToken = data.data.body['token'];
                const bodytoken = {
                  oldpaswd,
                  newpaswd,
                  mobileNbr,
                  patientCode,
                  jwtToken,
                  viasetpaswd,
                };
                jwtAxios
                  .post('v1/patMgmt/changepaswd', bodytoken)
                  .then((data) => {
                    if (data.status === 200) {
                      dispatch({type: FETCH_SUCCESS});
                      const getuserdata = data.data.message;
                      dispatch({
                        type: RESET_USER_PASWD,
                        payload: 'true',
                      });
                      dispatch({
                        type: SHOW_MESSAGE,
                        payload: getuserdata,
                      });

                      // dispatch({
                      //   type: UPDATE_AUTH_USER,
                      //   payload: { 
                      //     paswd:newpaswd, 
                      //   },
                      // });
                    } else {
                      dispatch({
                        type: RESET_USER_PASWD,
                        payload: 'incorrect',
                      });
                    }
                  })
                  .catch((error) => {
                    dispatch({type: FETCH_ERROR, payload: error.message});
                  });
              }
            } catch (err) {
              dispatch(fetchError(err.message));
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
    }
  };
};

// save user api
export const onSaveGudMedUser = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});

    //Call User list Token  - Get Token
    try {
      jwtAxios
        .post('v1/userMgmt/createjwt', value)
        .then((data) => {
          // console.log(data);
          try {
            // const jwtToken = data.data.body['token'];
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/userMgmt/create', value)
              .then((data) => {
                // console.log(data.data);
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});

                  dispatch({
                    type: GET_GUDMEDSUCCESS_DATA,
                    payload: data.data.message,
                  });
                  dispatch(
                    onGetUserManagementData({
                      userId: value.userId,
                      skip: '0',
                      limit: '10',
                    }),
                  );
                  dispatch({
                    type: SHOW_MESSAGE,
                    payload: data.data.message,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
              });
          } catch (err) {
            // console.log('error!!!!', err.response.message);
            dispatch(fetchError(err.response.message));
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
        });
    } catch (err) {
      // console.log('error!!!!', err.response.message);
      dispatch(fetchError(err.response.message));
    }
  };
};

// edit load event
export const onGeteditloadGudmed = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});

    jwtAxios
      .post('v1/userMgmt/getuserJwt', value)
      .then((data) => {
        try {
          value['jwtToken'] = data.data.body['token'];
          jwtAxios
            .post('v1/userMgmt/getuser', value)
            .then((data) => {
              // console.log(data.data, 'edit load data');
              if (data.data.status === 200) {
                dispatch({type: FETCH_SUCCESS});

                dispatch({
                  type: GET_GUDMED_USER,
                  payload: data.data.body,
                });
              } else {
                dispatch({
                  type: FETCH_ERROR,
                  payload: <IntlMessages id='message.somethingWentWrong' />,
                });
              }
            })
            .catch((error) => {
              dispatch({type: FETCH_ERROR, payload: error.message});
            });
        } catch (err) {
          // console.log('error!!!!', err.response.message);
          dispatch(fetchError(err.response.message));
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });

    //Call User list Token  - Get Token
    try {
      // console.log(value, 'edit apidVSD');
    } catch (err) {
      dispatch(fetchError(err.response.message));
    }
  };
};

// update api
export const onUpdateGudMedUser = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});

    //Call User list Token  - Get Token
    try {
      // console.log('call saving', value);
      jwtAxios
        .post('v1/userMgmt/updatejwt', value)
        .then((data) => {
          // console.log(data);
          try {
            // const jwtToken = data.data.body['token'];
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/userMgmt/update', value)
              .then((data) => {
                // console.log(data.data);
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});

                  dispatch({
                    type: GET_GUDMEDSUCCESS_DATA,
                    payload: data.data.message,
                  });
                  dispatch({
                    type: GET_GUDMED_USER,
                    payload: {},
                  });
                  dispatch(
                    onGetUserManagementData({
                      userId: value.userId,
                      skip: '0',
                      limit: '10',
                    }),
                  );
                  dispatch({
                    type: SHOW_MESSAGE,
                    payload: data.data.message,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
              });
          } catch (err) {
            // console.log('error!!!!', err.response.message);
            dispatch(fetchError(err.response.message));
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
        });
    } catch (err) {
      // console.log('error!!!!', err.response.message);
      dispatch(fetchError(err.response.message));
    }
  };
};
