/************
 * Developed by : Shiva Software Solutions
 * Date : 21-06-2021
 * Descriptions : Login AND Signup Functionality
 ************/

import {
  FETCH_START,
  FETCH_SUCCESS,
  FETCH_ERROR,
  SET_AUTH_TOKEN,
  SET_MOBILENO,
  SET_PASWD,
  SIGNOUT_AUTH_SUCCESS,
  UPDATE_AUTH_USER,
  OTP_SIGNUP_ERROR,
  SIGNIN_ERROR,
  OTP_SUCCESS,
  OTP_ERROR,
  PATIENT_NAME_LIST,
  APPOINTMENT_UPCOMING_COUNT,
} from '../../shared/constants/ActionTypes';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {
  fetchError,
  fetchStart,
  fetchSuccess,
  onWriteCloudWatchLog,
} from './Common';
import {AuthType} from '../../shared/constants/AppEnums';
//AWS cloud watch name
var streamname = 'Redux_Actions';
var filename = 'JWTAuth.js';

export const onJwtUserSignUp = ({email, password, name}) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    const body = {email, name, password};
    try {
      const res = await jwtAxios.post('users', body);
      localStorage.setItem('token', res.data.token);
      dispatch(setJWTToken(res.data.token));
      dispatch(loadJWTUser());
    } catch (err) {
      dispatch(fetchError(err.response.data.error));
    }
  };
};
export const onGetOtpfunction = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/patMgmt/getOtpPatient', value)
        .then((res) => {
          if (res.data.message.includes('Error')) {
            //  alert(res.data.message.split(':')[1])
            //  dispatch(fetchError(res.data.message.split(':')[1]));
            dispatch({
              type: OTP_SIGNUP_ERROR,
              payload: res.data.message.split(':')[1],
            });
          }
          if (res.data.body === 'OTP SENT') {
            dispatch({
              type: OTP_SIGNUP_ERROR,
              payload: '',
            });
            dispatch({
              type: OTP_SUCCESS,
              payload: true,
            });
          }
        })
        .catch((error) => {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Error',
          });
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetOtpfunction',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError('Error'));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetOtpfunction',
          '0',
          err,
        ),
      );
    }
  };
};

export const onGetLoginOtpfunction = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/patMgmt/getLoginOtpPatient', value)
        .then((res) => {
          if (res.data.message.includes('Error')) {
            //  alert(res.data.message.split(':')[1])
            //  dispatch(fetchError(res.data.message.split(':')[1]));
            dispatch({
              type: OTP_SIGNUP_ERROR,
              payload: res.data.message.split(':')[1],
            });
          }
          if (res.data.body === 'OTP SENT') {
            dispatch({
              type: OTP_SIGNUP_ERROR,
              payload: '',
            });
            dispatch({
              type: OTP_SUCCESS,
              payload: true,
            });
          }
        })
        .catch((error) => {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Error',
          });
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetLoginOtpfunction',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError('Error'));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetLoginOtpfunction',
          '0',
          err,
        ),
      );
    }
  };
};
export const onJwtSignIn = ({mobileNbr, paswd}) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {mobileNbr, paswd};
    try {
      await jwtAxios
        .post('v1/patMgmt/loginjwt', body)
        .then((res) => {
          if (res.data.hasOwnProperty.call(res.data, 'message')) {
            if (res.data.message.includes('Error')) {
              if (res.data.message.includes('2')) {
                dispatch({
                  type: SIGNIN_ERROR,
                  payload: 'Mobile No. or password is incorrect',
                });
              } else {
                dispatch({
                  type: SIGNIN_ERROR,
                  payload: 'This Mobile No. is not registered with us',
                });
              }
            }
          } else {
            localStorage.setItem('token', res.data.body.token);
            // localStorage.setItem('mobileNbr', mobileNbr);
            // localStorage.setItem('paswd', paswd);
            dispatch(setJWTToken(res.data.body.token));
            dispatch(setMobileNbr(mobileNbr));
            dispatch(setPaswd(paswd));
            var body = {mobileNbr, paswd, jwtToken: res.data.body.token};
            dispatch(loadJWTUser(body));
          }
        })
        .catch((error) => {
          dispatch({
            type: FETCH_ERROR,
            payload: error.response.data.message.split(':')[1].trim(),
          });
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onJwtSignIn',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onJwtSignIn', '0', err),
      );
    }
  };
};

export const loadJWTUser = (body) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    try {
      dispatch(fetchSuccess());
      await jwtAxios
        .post('v1/patMgmt/login', body)
        .then((res) => {
          if (res.status === 200) {
            var response = res?.data?.body?.uom && res.data.body.uom[0];
            var totalcount =
              res?.data?.body?.totalcount && res.data.body.totalcount;
            localStorage.setItem('roleId', '1');
            localStorage.setItem('vialogin', 'otp');
            dispatch({
              type: APPOINTMENT_UPCOMING_COUNT,
              payload: totalcount,
            });
            dispatch({
              type: UPDATE_AUTH_USER,
              payload: {
                authType: AuthType.JWT_AUTH,
                displayName: response.patname,
                email: response.email,
                paswd: response.paswd,
                mobileNo: response.mobileNo,
                patientCode: response.patientCode,
                roleId: 1,
                role: 'Patient',
                token: '',
                appointmentCount: totalcount,
              },
            });
          } else {
            if (res.data.message.includes('Error')) {
              if (res.data.message.includes('1')) {
                dispatch({
                  type: SIGNIN_ERROR,
                  payload: 'Mobile No. or password is incorrect',
                });
              }
              if (res.data.message.includes('2')) {
                dispatch({
                  type: SIGNIN_ERROR,
                  payload: 'This Mobile No. is not registered with us',
                });
              }
            }
          }
        })
        .catch((error) => {
          // alert(error.response.data.message.split(':')[1].trim());
          dispatch({type: UPDATE_AUTH_USER, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'loadJWTUser',
              '0',
              error,
            ),
          );
          return;
        });
    } catch (err) {
      dispatch(fetchError(err.response.error));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'loadJWTUser', '0', err),
      );
      return;
    }
  };
};

export const onGetForgotpaswdOtpfunction = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/patMgmt/getForgotPasswordOtpPatient', value)
        .then((res) => {
          if (res.hasOwnProperty.call(res.data, 'body')) {
            if (res.data.body === 'OTP SENT') {
              dispatch({
                type: OTP_ERROR,
                payload: '',
              });
              dispatch({
                type: OTP_SUCCESS,
                payload: true,
              });
            } else {
              dispatch({
                type: OTP_ERROR,
                payload: 'This Mobile No. is not registered with us',
              });
            }
          } else {
            dispatch({
              type: OTP_ERROR,
              payload: 'This Mobile No. is not registered with us',
            });
          }
        })
        .catch((error) => {
          dispatch({
            type: FETCH_ERROR,
            payload: error,
          });
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetForgotpaswdOtpfunction',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetForgotpaswdOtpfunction',
          '0',
          err,
        ),
      );
    }
  };
};
export const setJWTToken = (token) => {
  return async (dispatch) => {
    dispatch({
      type: SET_AUTH_TOKEN,
      payload: token,
    });
  };
};

export const setMobileNbr = (number) => {
  return async (dispatch) => {
    dispatch({
      type: SET_MOBILENO,
      payload: number,
    });
  };
};

export const setPaswd = (paswd) => {
  return async (dispatch) => {
    dispatch({
      type: SET_PASWD,
      payload: paswd,
    });
  };
};

export const onJWTAuthSignout = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      setTimeout(() => {
        dispatch({type: SIGNOUT_AUTH_SUCCESS});
        dispatch({type: FETCH_SUCCESS});
        localStorage.removeItem('token');
        localStorage.removeItem('searchpatid');
        localStorage.clear();
        dispatch({
          type: PATIENT_NAME_LIST,
          payload: [],
        });
      }, 500);
    } catch (err) {
      dispatch(fetchError(err.response.message));
    }
  };
};

export const onJwtSignInWithOTP = ({mobileNbr, otp}) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {mobileNbr, otp};
    try {
      await jwtAxios
        .post('v1/patMgmt/loginwithOtpjwt', body)
        .then((res) => {
          if (res.data.hasOwnProperty.call(res.data, 'message')) {
            if (res.data.message.includes('Error')) {
              if (res.data.message.includes('1')) {
                dispatch({
                  type: SIGNIN_ERROR,
                  payload: 'Invalid OTP',
                });
              }
              if (res.data.message.includes('2')) {
                dispatch({
                  type: SIGNIN_ERROR,
                  payload: 'Your OTP is expired , Please try again!',
                });
              }
            }
          } else {
            localStorage.setItem('token', res.data.body.token);
            // localStorage.setItem('mobileNbr', mobileNbr);
            // localStorage.setItem('paswd', paswd);
            dispatch(setJWTToken(res.data.body.token));
            dispatch(setMobileNbr(mobileNbr));
            dispatch(setPaswd(''));
            var body = {mobileNbr, otp, jwtToken: res.data.body.token};
            dispatch(loadJWTOTPUser(body));
          }
        })
        .catch((error) => {
          dispatch({
            type: FETCH_ERROR,
            payload: error.response.data.message.split(':')[1].trim(),
          });
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onJwtSignIn',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onJwtSignIn', '0', err),
      );
    }
  };
};

export const loadJWTOTPUser = (body) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    try {
      dispatch(fetchSuccess());
      await jwtAxios
        .post('v1/patMgmt/loginwithOtp', body)
        .then((res) => {
          if (res.status === 200) {
            var response = res.data.body[0];
            localStorage.setItem('roleId', '1');
            localStorage.setItem('vialogin', 'otp');
            dispatch({
              type: OTP_SUCCESS,
              payload: false,
            });
            dispatch({
              type: UPDATE_AUTH_USER,
              payload: {
                authType: AuthType.JWT_AUTH,
                displayName: response.patname,
                email: response.email,
                mobileNo: response.mobileNo,
                patientCode: response.patientCode,
                paswd: response.paswd,
                roleId: 1,
                role: 'Patient',
                token: '',
              },
            });
          } else {
            if (res.data.message.includes('Error')) {
              if (res.data.message.includes('1')) {
                dispatch({
                  type: SIGNIN_ERROR,
                  payload: 'Mobile No. or password is incorrect',
                });
              }
              if (res.data.message.includes('2')) {
                dispatch({
                  type: SIGNIN_ERROR,
                  payload: 'This Mobile No. is not registered with us',
                });
              }
            }
          }
        })
        .catch((error) => {
          // alert(error.response.data.message.split(':')[1].trim());
          dispatch({type: UPDATE_AUTH_USER, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'loadJWTUser',
              '0',
              error,
            ),
          );
          return;
        });
    } catch (err) {
      dispatch(fetchError(err.response.error));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'loadJWTUser', '0', err),
      );
      return;
    }
  };
};
